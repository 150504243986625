import revive_payload_client_MX2BE4EHjP from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_6agv4a7sxc2ostejhmeohafv3a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZSVEsFpDDK from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_6agv4a7sxc2ostejhmeohafv3a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bKDSsDfNoJ from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_6agv4a7sxc2ostejhmeohafv3a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_eEpRvx2hjX from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_6agv4a7sxc2ostejhmeohafv3a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/website/.nuxt/components.plugin.mjs";
import prefetch_client_fV46E4BybM from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_6agv4a7sxc2ostejhmeohafv3a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DfmeysVFcf from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_pd2xdatr2pi7bri7hawfci227i/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_0xxIX4MkAg from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_RdrjpRzKQi from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_UrocyZBZMe from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_1Os97y8obV from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_6agv4a7sxc2ostejhmeohafv3a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/opt/render/project/src/apps/website/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_EAx64ccgHL from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_MX2BE4EHjP,
  unhead_ZSVEsFpDDK,
  router_bKDSsDfNoJ,
  check_outdated_build_client_eEpRvx2hjX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fV46E4BybM,
  plugin_DfmeysVFcf,
  plugin_client_0xxIX4MkAg,
  switch_locale_path_ssr_RdrjpRzKQi,
  i18n_UrocyZBZMe,
  chunk_reload_client_1Os97y8obV,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_EAx64ccgHL
]